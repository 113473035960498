import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const createUser = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post (process.env.VUE_APP_SSO_API_URL + 'api/sso-user/create',payload).then((response) => {
    
    return response.data.success;
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true });
  
    if(error.response.status == '422'){
        commit('SET_ALERT_MESSAGE', 'The email has already been taken', { root: true });
        context.userValidation.duplicateValidation = false;
    }else{
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
    }
    context.isbeingCreating = false;
    return false
  })
}
export const restAddNewUser = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post (process.env.VUE_APP_JIIPA_API_URL + 'api/add-new-user',payload).then((response) => {
    return response.data.success;
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true });
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
    context.isbeingCreating = false;
    return false;
  })
}
export const fetchUserList = ({ commit, dispatch }, query = '') => {
  return window.axios.get (process.env.VUE_APP_JIIPA_API_URL + 'api/list-user'+ query).then((response) => {
    commit('setAuthenticated', true);
    commit('setUserList', response);
  })
}
export const getUserRolesData = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles').then((response) => {
    commit('SET_ROLE_LIST', response);
    return response.data;
  }).catch((error) => {
    if (error.response.data.message != '') {
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
    }
      return false;
  })
}
export const deleteUser = ({ commit, state, dispatch },{payload, context, email}) => {
  return window.axios.post (process.env.VUE_APP_SSO_API_URL + `api/users/sso-delete/${email}`,payload).then((response) => {
    commit('setAuthenticated', true);
    return true;
  }).catch((error) => {
    console.log('error.response',error.response);
    commit('SET_ALERT', 'warning', { root: true });
    commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
    context.isbeingDeleted = false;
    return false
  })
}
export const restDeleteUserData = ({ commit, dispatch }, {payload, context, id}) => {
  return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/delete-user/${id}`).then((response) => {
      return response.data;
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      context.isbeingDeleted = false;
      return false
  })
}
export const editUserData = ({ commit, dispatch }, payload) => {
  return window.axios.get (process.env.VUE_APP_JIIPA_API_URL + `api/get-user-data/`+payload).then((response) => {
    commit('setUserDetails', response.data);
    return response.data;
  }).catch((error) => {
    console.log('e',error);
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false;
  })
}
export const getSSOuser = ({ commit, dispatch }, {payload, context}) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + `api/sso-user/details/` + payload).then((response) => {
      return response.data.data;
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false
  })
}
export const updateUser = ({commit}, { payload, context, id }) => {
  return window.axios({
    method: 'POST',
    url: process.env.VUE_APP_SSO_API_URL + `api/sso-user/update-details/${payload.email}`,
    data: payload,
  }).then((response) => {
    commit('setAuthenticated', true);
    return response.data.success
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true });
    if(error.response.status == '500'){
        commit('SET_ALERT_MESSAGE', 'The email has already been taken', { root: true });
        context.userValidation.duplicateValidation = false;
    }else{
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
    }
    context.isbeingCreating = false;
    return false
  })
}

export const restUpdateUser = ({ commit }, { payload, context }) => {
  return window.axios.post (process.env.VUE_APP_JIIPA_API_URL +'api/update-user-details/'+payload.id, payload).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return true
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      context.isbeingCreating = false;
      return false;
  })
}
export const removeProfile = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.delete ("/api/remove-profile-pic/"+payload).then((response) => {

  return response.data.success;
}).catch((error) => {
  context.btnDelete = context.btnLable;
  context.isDisable = false; 
  return false
})
}




