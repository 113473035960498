// -----------Add Newsletter -------------

export const addNewsLetter = ({ commit}, { payload, context }) => {
    console.log(payload);
    return window.axios.post( process.env.VUE_APP_JIIPA_API_URL + 'api/newsletter-store', payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
       // dispatch("fetchNewsLetterList")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// ~~~~~~~~~~~~~~~~~~ List Newsletter ~~~~~~~~~~~~
export const fetchNewsLetterList = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/newsletter-list').then((response) => {
        commit('setNewsLetterList', response.data.data);
        return response.data.data;
    })
}

// ~~~~~~~~~~~~~~~~~~ Delate Newsletter ~~~~~~~~~~~~
export const deleteNewsletterlist = ({ commit, state, dispatch }, id) => {
     id = state.DeleteList
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/newsletter-delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchNewsLetterList")
        // window.location = "newsletter"   
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}


// ~~~~~~~~~~~~~~~~~~ download Newsletter Pdf~~~~~~~~~~~~
export const downloadNewsLetterPdf = ({ commit }, id) => {

    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/pdf-download/${id}`,{responseType: 'blob'}).then((response) => {    
        // commit('setNewsletterPdfDownload', response.data.data);
        // console.log(response.data.data);
        // console.log("response.data.data");
         //Create a Blob from the PDF Stream
    // const file = new Blob(
    // [response.data], 
    // {type: 'application/pdf'});
    // //Build a URL from the file
    // const fileURL = URL.createObjectURL(file);
    // //Open the URL on new Window
    // window.open(fileURL);
     const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setNewsletterPdfDownload('download', 'newsletter_pdf.pdf');
  document.body.appendChild(link);
  link.click();
    })
     console.log(response)
}

// ~~~~~~~~~~~~~~~~ Edit Newsletter ~~~~~~~~~~~~~~~~~~

// export const editNewsletterById = ({ commit}, id) => {
//     console.log('theDataShowinEdit')
//     console.log(id);
//     return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/newsletter-edit/${id}`).then((response) => { 
//     console.log('response');       
//         commit('setShowEditNewsletter', response.data.data)
//         console.log(response.data);
//         commit('showEditNewsletter', true)   
//       })
// } 

export const fetchNewsletterById = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/newsletter-edit/${id}`).then((response) => {   
        console.log(response.data,response);
        return response.data
    })
}

// ~~~~~~~~~~~~~~ Update Newsletter ~~~~~~~~~~~

export const updateNewsletter = ({ commit, dispatch }, { payload,  context , id}) => {
console.log('updateNewsletter');
    console.log(payload, id);
    return window.axios.post( process.env.VUE_APP_JIIPA_API_URL + `api/newsletter-update/${id}`, payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        window.location = "newsletter"        
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}


// -----------------------==== Delete Images through Update Newsletter ====---------------------
export const DeleteImageNewsletter = ({ commit, state, context, dispatch }, id) => {
    console.log(id);
    console.log('hii Delete');
    id = state.updateImageNewsletterIdDelete
    return window.axios.delete (process.env.VUE_APP_JIIPA_API_URL + `api/newsletter-images-delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        // context.errors = error.response.data
        return false
    })
}