import localforage from 'localforage'
export const getUserRolesData = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles').then((response) => {
    commit('SET_ROLE_LIST', response)
    return true
  }).catch((error) => {
    if (error.response.data.message != '') {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
    }
    return false
  })
}

export const fetchUserById = ({ commit, dispatch }) => {
  const email = localStorage.getItem('email');
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/sso-user/details/'+email).then((response) => {
      window.axios.get('/api/get-profile-details/'+response.data.data.details.email).then((result) => {
        commit('SET_EDIT_USER_DATA', result);
        return result.data;
      }).catch((error) => {
        return false
      })
  }).catch((error) => {
    return false
  })
}

export const cityData = ({ commit, context}, query = 'some') => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/get-city/" + query).then((response) => {
    commit("SET_CITY_DATA", response.data);
    return true;
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
    context.errors = error.response.data.errors;
    return false;
  });   
};

export const stateData = ({ commit, context }, id) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/states", {
    params: { id },
  }).then((response) => {
      // commit("setAuthenticated", true);
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.data;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong State", { root: true });
      context.errors = error.response.data.errors;
      return false;
    })  
  };

  export const updateProfile = ({ commit }, { payload, context , id}) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/sso-user/update-details/'+payload.email, payload,{
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
      }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false
      });
    }

    export const profilePic = ({ commit, dispatch }, { payload, context }) => {
      return window.axios.post (process.env.VUE_APP_SSO_API_URL + "api/profile-pic", payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then((response) => {
    
      return response.data.user
    }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
    })
  }
  export const restUpdateProfileUrl = ({commit}, {payload, context})=> {
    return window.axios.put('/api/update-profile-details/'+payload.email, payload,{
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success;
      }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
      });
  }

  export const restProfilePicUrl = ({ commit, dispatch }, { payload, context, email }) => {
    return window.axios.post ("/api/profile-details/profile-pic/"+email, payload,{
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
    // Showing  success message
    commit('SET_ALERT', 'success', { root: true })
    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
    return response.data.success;
  }).catch((error) => {
    context.isbeingCreating = false;
    context.btnName = context.btn;
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    return false
  })
}

export const removeProfile = ({ commit, dispatch }, email) => {
  return window.axios.delete ("/api/remove-profile-pic/"+email).then((response) => {
  // Showing  success message
  commit('SET_ALERT', 'success', { root: true })
  commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
  return response.data.success
}).catch((error) => {
  commit('SET_ALERT', 'warning', { root: true })
  commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
  context.errors = error.response.data
  return false
})
}

export const getDepartmentOrDesignationList = ({ commit, dispatch }, payload) => {
  return window.axios.get ("/api/get-list/"+payload.type+'?search=' +payload.search).then((response) => {
  return response.data;
}).catch((error) => {
  commit('SET_ALERT', 'warning', { root: true })
  commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
  context.errors = error.response.data
  return false
})
}

export const changePassword = ({ commit }, { payload, context}) => {
  return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/change-password', payload).then((response) => {
    return true;
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true });
    commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
    //console.log('error.response.data',error.response.data.message);
    context.btnName = context.btn;
    context.isbeingCreating = false;
    
    return false
  });
}