export const SET_ROLE_LIST = (state, data) => {
    state.myRoles = data.data
}

export const SET_EDIT_USER_DATA = (state, data) => {
	state.myProfile.name = data.data.data.firstname
	state.myProfile.email = data.data.data.email
	state.myProfile.user_role = data.data.data.sso_user_role
	state.myProfile.last_name = data.data.data.lastname 
	state.myProfile.company_logo = data.data.data.company_logo 
	state.myProfile.designation = {
		sso_designation: data.data.data.sso_designation,
	}
	state.myProfile.department ={
		sso_department: data.data.data.sso_department,
	} 
	state.myProfile.pin_code = data.data.data.zipcode 
	state.myProfile.company_name = data.data.data.company_name
	state.myProfile.city = {
		id: 0,
		name: data.data.data.city ,
		sname: data.data.data.state,
		state_id: 0
	}
	state.myProfile.state = data.data.data.state 
	state.myProfile.country = data.data.data.country 
	state.myProfile.address_line_1 = data.data.data.address_line_1
	state.myProfile.address_line_2 = data.data.data.address_line_2
	state.myProfile.address_line_3 = data.data.data.address_line_3 
	state.myProfile.company_phonecode = data.data.data.company_phone_code && data.data.data.company_phone_country_id ? data.data.data.company_phone_code+'#@#'+data.data.data.company_phone_country_id : '';
	state.myProfile.company_tel = data.data.data.phone_number 
	state.myProfile.mobile_phonecode = data.data.data.mobile_phone_code && data.data.data.mobile_phone_country_id ? data.data.data.mobile_phone_code+'#@#'+data.data.data.mobile_phone_country_id : '';
	state.myProfile.company_fax_phonecode = data.data.data.fax_phone_code && data.data.data.fax_phone_country_id ? data.data.data.fax_phone_code+'#@#'+data.data.data.fax_phone_country_id : '';
	state.myProfile.company_fax = data.data.data.company_fax 
	state.myProfile.mobile_number = data.data.data.mobile_number 
	state.myProfile.website = data.data.data.website 
	state.myProfile.profile_pic = data.data.data.profile_image ? data.data.data.profile_image : '/images/Vectorprofile.png'

	let storeData = {
		name : data.data.data.firstname,
		last_name : data.data.data.lastname,
		designation : {
			sso_designation: data.data.data.sso_designation,
		},
		user_role : data.data.data.sso_user_role,
		profile_pic : data.data.data.profile_image ? data.data.data.profile_image : '/images/Vectorprofile.png'
	}
	localStorage.setItem('user_data',JSON.stringify(storeData));
}

